// extracted by mini-css-extract-plugin
export var block = "project-module--block--O88dm";
export var blockMedia = "project-module--block-media--INShP";
export var blockCaption = "project-module--block-caption--dvHvS";
export var columns = "project-module--columns--y21z3";
export var spacer = "project-module--spacer--Pm9fr";
export var video = "project-module--video--nFOJR";
export var image = "project-module--image--bMWPP";
export var spotify = "project-module--spotify---VJdq";
export var vimeoWrapper11 = "project-module--vimeo-wrapper-1-1--+H2Rx";
export var vimeoWrapper169 = "project-module--vimeo-wrapper-16-9--Yizm2";
export var vimeoWrapperWide = "project-module--vimeo-wrapper-wide--y8+S0";
export var vimeo = "project-module--vimeo--fqjpV";