import * as React from 'react';
import * as styles from './project.module.css';

const Block = ({ children, caption }) => (
    <div className={styles.block}>
        <div className={styles.blockMedia}>
            {children}
        </div>
        {caption && <div className={styles.blockCaption}>
            {caption}
        </div>}
    </div>
)

const Columns = ({ children }) => (
    <div className={styles.columns}>
        {children}
    </div>
)

const Spacer = () => (
    <div className={styles.spacer}></div>
)

const Video = ({ source, width, backgroundColor, controls = false, autoPlay = true, muted = true, loop = true }) => (
    <video style={{maxWidth: width ? width : "max-content", backgroundColor: backgroundColor ? backgroundColor : "none"}} className={styles.video} autoPlay muted loop playsInline>
      <source src={source} type="video/mp4"/>
    </video>
)

const Vimeo = ({ source, ratio = "16:9" }) => {
    const ratioClassName = 
        ratio === "16:9" ? styles.vimeoWrapper169 : 
            (ratio === "1:1" ? styles.vimeoWrapper11 : (
                ratio === "wide" ? styles.vimeoWrapperWide : ""));

    return (
        <div className={ratioClassName}>
            <iframe className={styles.vimeo} src={source} width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="Visuals for 20 Min by Lil Uzi Vert"></iframe>
        </div>
    );
}

const YouTube = ({ source }) => (
    <div className={styles.vimeoWrapper169}>
        <iframe className={styles.vimeo} width="100%" height="100%" src={source} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
)

const Spotify = ({ source }) => (
    <iframe title="spotify player" className={styles.spotify} src={source} width="400" height="80" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
)

const Image = ({ source, alt, width }) => (
    <img className={styles.image} style={{maxWidth: width ? width : "max-content"}}
        src={source}
        alt={alt}
    />
)

export {
    Block,
    Columns,
    Spacer,
    Video,
    Vimeo,
    YouTube,
    Image,
    Spotify
}